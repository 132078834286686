import type { ISharedKitButtonColorPreset } from '~/components/SharedKitButton/SharedKitButton.types';
import { Colors } from '~/constants/colors.constants';
import { BattlesColors } from '~/features/battles/constants/colors';

type TColorPresetsKeys = 'MAIN' | 'BOT' | 'TRANSPARENT_WHITE';

export const BattleColorPresets: Record<TColorPresetsKeys, ISharedKitButtonColorPreset> = {
  BOT: {
    defaultBackground: BattlesColors.BOT_BLUE_DARK[0],
    hoverBackground: BattlesColors.BOT_BLUE[0],
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
  MAIN: {
    defaultBackground: {
      colorStops: BattlesColors.MAIN,
      toDirection: '90deg',
    },
    hoverBackground: {
      colorStops: BattlesColors.MAIN_LIGHT,
      toDirection: '90deg',
    },
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
  TRANSPARENT_WHITE: {
    defaultBackground: Colors.NEUTRAL.WHITE_15,
    hoverBackground: Colors.NEUTRAL.WHITE_30,
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
};
